.topup{
    background-color: var(--topup-bg);
    min-height: 100vh;
}
.topup .burst{
    opacity: .3;
}
.topup-wrapper{
    padding: 0 1em;
    margin-top: -1em;
}
.topup-body{
    position: relative;
    background-image: url('https://s.tykcdn.com/i/mq/images/bg-tu-center.svg');
    background-repeat: no-repeat;
    background-position-y: center;
    background-size: 100%;
    padding: 0em 2em;
    z-index: 1;
    margin: -2em 0 -3em;
}
.ti-head{
    position: relative;
    background-image: url('https://s.tykcdn.com/i/mq/images/bg-tu-top.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    padding-bottom: 27.5%;
}
.ti-foot{
    position: relative;
    background-image: url('https://s.tykcdn.com/i/mq/images/bg-tu-bottom.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    padding-bottom: 27.5%;
}
.topup-body-head{
    font-size: var(--fsize2);
    font-weight: 700;
    margin-bottom: .5em;
    line-height: 1.2;
    text-align: center;
    color: var(--topup-body-head-color);
}
.topup-box{
    background-color: var(--topup-box-bg);
    border: var(--topup-box-border);
    border-radius: 20px;
    padding: .65em .5em 2.25em;
    margin-bottom: 1.5em;
}
.topup-box:last-child{
    margin-bottom: 0;
}
.topup-box-head{
    background-color: var(--topup-box-head-bg);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .25em .75em;
}
.topup-box-head .btn{
    border-radius: 50%;
}
.topup-box-head h6{
    font-weight: 500 !important;
    margin: 0 !important;
    margin-bottom: 0;
}
.topup-box-head img{
    width: 1.35em;
}
.topup-box h6{
    font-weight: 300;
    text-align: center;
    margin: .65em 0 .75em;
}
.topup-box h6 b{
    font-weight: 500;
}
.topup-box-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--topup-box-wrapper-color);
}
.topup-box-wrapper span{
    position: relative;
    width: 50%;
    background-color: var(--topup-box-wrapper-bg);
    border-radius: 10px;
    margin-right: .75em;
    padding: 1.25em .5em 0;
}
.topup-box-wrapper span:last-child{
    margin-right: 0;
}
.tbw-top{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: .5em;
}
.tbw-top h2{
    margin-bottom: 0;
    margin-left: .25em;
}
.tbw-top img{
    width: 2.75em;
}
.tbw-text{
    font-weight: 500;
    text-align: center;
    margin: .25em 0;
}
.topup-box-wrapper .btn{
    width: 100%;
    font-size: var(--fsize1);
    padding: .15em .25em;
    margin-bottom: -1em;
    border-radius: 5px;
    color: var(--btn-topup-power-color);
}
.btn-topup-power{
    background-color: var(--btn-topup-power-bg);
    border: var(--btn-topup-power-border);
    text-shadow: var(--btn-topup-power-ts);
}
.btn-topup-heart{
    background-color: var(--btn-topup-heart-bg);
    border: var(--btn-topup-heart-border);
    text-shadow: var(--btn-topup-heart-ts);
}

.modal-topup{
    color: var(--modal-topup-color);
}
.modal-topup .modal-content{
    padding: 4.5em 1.5em 3em;
    border: none;
    border-radius: 20px;
    line-height: 1.4;
}
.btn-close-mt{
    position: absolute;
    top: .5em;
    right: .5em;
    width: 2.25em;
}
.modal-topup h4{
    font-weight: 600;
    margin-left: .25em;
}
.modal-topup p{
    font-size: var(--fsize2);
    margin-bottom: .5em;
}
.modal-topup ul{
    list-style: none;
    padding-left: 1.5em;
    font-size: var(--fsize2);
}

@media only screen and (max-width: 992px) and (max-height: 812px){
    .topup-outer, .topup{
        height: unset;
        min-height: 100vh;
    }
}