.text{
    color: var(--text-color);
    height: unset;
    max-height: unset;
}
.text-bdy{
    padding: 1.25em 1.25em 1.75em 2.5em;
}
.text-bdy ol{
    padding-left: 0;
    font-size: var(--fsize1);
    font-weight: 300;
    line-height: 1.4;
}
.text-bdy ol li{
    margin-bottom: 1.25em;
}
.text-bdy table{
    text-align: center;
    width: 100%;
    margin-top: .75em;
}
.text-bdy table tr th{
    background-color: var(--text-table-head-bg);
    border: var(--text-table-border);
    padding: .35em .25em;
    font-weight: 500;
}
.text-bdy table tr td{
    border: var(--text-table-border);
    padding: .35em .25em;
}

.text-faq{
    /*height: 100vh;*/
    /*max-height: 812px;*/
}
.text-faq .text-bdy{
    padding: 1.25em 0 2.5em;
}
.acc-item{
    background-color: var(--acc-item-bg);
    border-top: var(--acc-item-border);
    border-bottom: var(--acc-item-border);
    margin-bottom: 1.25em;
}
.acc-item:last-child{
    margin-bottom: 0;
}
.acc-i-head{
    color: var(--acc-head);
    font-size: var(--fsize2);
    font-weight: 500;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.acc-i-head span:nth-child(1){
    padding-right: 1em;
}
.acc-i-head span:nth-child(2) img{
    width: .95em;
    transition: 200ms;
}
.acc-i-head.collapsed span:nth-child(2) img{
    transform: rotate(-180deg);
}
.acc-i-body{
    font-size: var(--fsize1);
    padding: .25em 1.25em 2.5em;
}

@media only screen and (max-width: 992px) and (max-height: 812px){
    .text-faq{
        height: unset;
        min-height: 100vh;
        max-height: unset;
    }
}