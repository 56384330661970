:root{
  --fsmall7: .55em;
  --fsmall6: .65em;
  --fsmall5: .7em;
  --fsmall4: .75em;
  --fsmall3: .8em;
  --fsmall2: .85em;
  --fsmall1: .9em;
  --fsmall: .95em;
  --fsize: 14px;
  --fsize1: 1.1em;
  --fsize2: 1.2em;
  --fsize3: 1.3em;
  --fsize4: 1.4em;
  --fsize5: 1.5em;
  --fsize6: 1.75em;
  --fsizebig1: 2.25em;
  --fsizebig2: 3em;
  --fsizebig2-2: 3.5em;
  --fsizebig3: 5em;
  --fsizebig4: 7em;
  --fsizetiny: 10em;
  --shadow: 0px 0px 4px 0px rgba(0,0,0,.5);
  --shadow2: 1px 1px 6px 0px rgba(0,0,0,.2);
}
body, html{
  height: 100%;
}
body{
  padding: 0;
  margin: 0;
}

/* ---- start general css ----*/
/* --button-- */
a, a:hover{
  color: inherit;
  text-decoration: none;
}
.btn{
  position: relative;
  overflow: hidden;

  cursor: pointer;
  display: block;
  position: relative;
  z-index: 1;
  padding: 0;
}
.btn:active, .btn:focus{
  outline: none !important;
  box-shadow: none;
}
.btn:active, a.btn:active{
  transform: scale(.98);
}
.btn::after{
  content: '';
  position: absolute;
  z-index: -1;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  background-color: rgba(255,255,255, 0.7);
  width: 30px;
  height: 30px;
  border-radius: 100%;
  transform: scale(0);
}
.btn:focus:not(:active)::after{
  -webkit-animation: circleGrow 0.3s linear;
  -moz-animation: circleGrow 0.3s linear;
  -ms-animation: circleGrow 0.3s linear;
  -o-animation: circleGrow 0.3s linear;
  animation: circleGrow 0.3s linear;
}
@-webkit-keyframes circleGrow{
  0%{
    transform: scale(0);
    opacity: 0;
  }
  20%{
    opacity: 1;
  }
  100%{
    transform: scale(6);
    opacity: 0;
  }
}
@-moz-keyframes circleGrow{
  0%{
    transform: scale(0);
    opacity: 0;
  }
  20%{
    opacity: 1;
  }
  100%{
    transform: scale(6);
    opacity: 0;
  }
}
@-o-keyframes circleGrow{
  0%{
    transform: scale(0);
    opacity: 0;
  }
  20%{
    opacity: 1;
  }
  100%{
    transform: scale(6);
    opacity: 0;
  }
}
@keyframes circleGrow{
  0%{
    transform: scale(0);
    opacity: 0;
  }
  20%{
    opacity: 1;
  }
  100%{
    transform: scale(6);
    opacity: 0;
  }
}
/* --button-- */

.root{
  position: relative;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: var(--fsize);
  color: #FFF;
  background-image: var(--root-bg);
  background-attachment: fixed;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content{
  position: relative;
  width: 100%;
  height: 100vh;
  max-width: 480px;
  background-image: var(--background);
  margin: 0 auto;
}

/*popup custom*/
.modal-outer.active .modal{
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-backdrop{
  visibility: hidden;
  -webkit-transition: ease 100ms;
  -moz-transition: ease 100ms;
  -ms-transition: ease 100ms;
  transition: ease 100ms;
}
.modal-backdrop.show{
  visibility: visible;
}
.modal.fade.show{
  display: block;
}
/*end popup custom*/

/*accordion custom*/
.collapse.show{
  display: block;
}
/*end accordion custom*/

input{
  color: #000 !important;
  font-weight: 700 !important;
}
input::placeholder{
  color: #ADADAD !important;
  font-weight: 300 !important;
}
input:focus, input.form-control:focus{
  box-shadow: inset 0 0 0 #DDD;
  outline: none !important;
}

.carousel{
  margin: auto -1.5em;
}
.carousel-cell:first-child{
  left: 1.25em !important;
  margin-right: 1.75em !important;
}
.carousel-cell{
  margin-right: .5em;
}
.last-slide{
  margin-left: .75em !important;
}

img{
  width: 100%;
}

.loading-content{
  position: relative;
  padding: 1em 0;
  margin: 3em 0;
}
.loading-content svg{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.burst-outer{
  position: relative;
  clip: rect(0, auto, auto, 0);
  overflow: hidden;
  -webkit-box-shadow: var(--shadow);
  -moz-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
}
.burst{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  height: 100%;
  min-height: 200vh;
  width: 100%;
  min-width: 200vw;
  opacity: .1;
}
.burst-inner{
  background-image: url('https://s.tykcdn.com/i/mq/images/brust.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

.copyright{
  position: absolute;
  bottom: 1em;
  left: 50%;
  transform: translate(-50%,0);
}
/* ---- end general css ----*/

/* ---- responsive ----*/
@media only screen and (max-width: 350px){
  :root{
    --fsize: 12px;
  }
}
@media only screen and (min-width: 992px) and (max-height: 700px){
  .content{
    max-width: 375px;
  }
}
@media only screen and (min-width: 992px) and (min-height: 700px){
  .content{
    max-height: 812px;
  }
}
/* ---- end responsive ----*/