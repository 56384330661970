.login{
    background-image: url('https://s.tykcdn.com/i/mq/images/bg-mq.jpg');
    /* background-image: url('http://tykcdn.com/img500/match_quiz/web/background_Matchquiz_WEB_full-screen_ramadhan.jpg'); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.login-logo{
    padding: 5em 0 1.75em;
}
.login-logo img{
    max-width: 10em;
    margin: 0 auto;
    display: block;
}
.login-wrapper{
    background-color: var(--login-wrapper-bg);
    border-radius: 10px;
    margin: 0 1em;
    padding: 1.5em .75em 2.5em;
}
.login-wrapper h2{
    line-height: 1.1;
    margin-left: .35em;
    margin-bottom: .5em;
}
.login-wrapper .form-control{
    margin-bottom: .65em;
}
.login-wrapper input{
    border-radius: 10px;
    font-size: var(--fsize3);
    padding: 0 1.25em;
    height: 2.5em;
    -webkit-box-shadow: var(--shadow2);
    -moz-box-shadow: var(--shadow2);
    box-shadow: var(--shadow2);
}
.login-wrapper .btn{
    width: 100%;
    border-radius: 10px;
    font-size: var(--fsize4);
    padding: .35em .5em;
    text-shadow: var(--edit-btn-ts);
    -webkit-box-shadow: var(--shadow2);
    -moz-box-shadow: var(--shadow2);
    box-shadow: var(--shadow2);
}
.login-btn-login{
    background-image: var(--login-btn-bg);
    color: var(--login-btn-color) !important;
    font-weight: 600;
}
.login-or{
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #FFF;
    line-height: 0;
    margin: 1.65em 0;
    font-size: var(--fsize3);
} 
.login-or span{
    background: var(--login-wrapper-bg); 
    padding: 0 1em; 
}
.login-btn-signup{
    background-color: var(--signup-btn-bg);
    color: var(--signup-btn-color) !important;
    border: var(--signup-btn-border);
    font-weight: 500;
}

@media only screen and (max-width: 374px) and (max-height: 666px){
    .login{
        height: unset;
        min-height: 100vh;
        padding-bottom: 3em;
    }
    .login-wrapper{
        margin-bottom: 2em;
    }
}