:root{
    --root-bg: radial-gradient(#2149BA,#001349);
    /*home*/
    --background: linear-gradient(#003D5A,#005286);
    --header-bg: linear-gradient(#0092AD,#003D67);
    --h-ts: 1px 2px #075B72;
    --header-inner-bg: radial-gradient(ellipse 60% 50%, #004C81,#022640);
    --hi-green-bg: linear-gradient(#31D97B,#00AD57);
    --hi-green-border: 2px solid #20BE61;
    --hi-green-ts: 1px 1px #15AF54;
    --hi-yellow-bg: linear-gradient(#FFD800,#FF9300);
    --hi-yellow-border: 2px solid #FFB100;
    --hi-yellow-ts: 1px 1px #FF4E0029;
    --slider-category-bg: #054162;
    --hr: 2px solid #135B88;
    --sc-item-live: #FF0707;
    --loader: #017489;
    --loading-q-bg: #005888;

    /*character*/
    --select-character-bg: #043A60;
    --sci-item-inner-bg: radial-gradient(#73B7E8 15%,#03558E 75%);
    --sci-item-inner-border: 5px solid #06477E;
    --sci-item-inner-selected-bg: radial-gradient(#EBFF00 15%,#FFC400 75%);
    --sci-item-inner-selected-border: 5px solid #FED700;
    --btn-check-in-bg: radial-gradient(ellipse 60% 50%,#58F2A6,#00AD57);
    --btn-check-in-color: #FFF;
    --btn-check-in-border: 3px solid #21C770;
    --btn-check-in-shadow: 1px 2px #42900029;

    /*waiting*/
    --waiting-box-bg: radial-gradient(ellipse 60% 50%,#FC5171,#C70026);
    --waiting-box-border: 5px solid #E64242;

    /*countdown*/
    --countdown-time-ts: 0 3px #00000029;

    /*question live*/
    --qw-top-bg: rgba(3,20,49,.9);
    --qw-top-border: 3px solid #0083E3;
    --timer-bg: #FFF;
    --timer-color: #000;
    --timer-circle-color: #FF001D;

    --qwa-item-border: 1px solid rgba(255,255,255,.05);
    --qwa-item-selected-bg: rgba(0,216,255,.3);
    --qwa-text-bg: #2C2C2C;
    --qwa-text-border: 2px solid #222222;
    --qwa-text-wrong-bg: #CA204E;
    --qwa-text-wrong-border: 2px solid #9F002B;
    --qwa-text-correct-bg: #1DB15C;
    --qwa-text-correct-border: 2px solid #049140;
    --qwa-char-bg: rgba(255,255,255,.1);
    --qwa-char-selected-bg: #FFE200;
    --qwa-char-span-bg: rgba(255,255,255,.1);

    --popup-go-bg: radial-gradient(#FF4F38,#BE2410);
    --popup-go-border: 7px solid #801305;

    /*question sd*/
    --qw-top-sd-bg: radial-gradient(#FF4F38,#BE2410);
    --qw-top-sd-border: 3px solid #801305;
    --qwsda-item-selected-border: #00CEFF;
    --qwsd-bottom: radial-gradient(#051B79,#000D43);

    /*score*/
    --score-bg: #8A0000;
    --stbi-gd: radial-gradient(#FF9300 20%,transparent 50%);
    --score-total-top-bg: #FF9300;
    --score-total-bg: linear-gradient(#FF9300,#D13F31);
    --st-circle-bg: radial-gradient(#FFD800 15%,#FF9300 75%);
    --st-circle-border: 5px solid #FFA700;
    --st-circle-inner-ts: 0px 3px #FF930029;
    --st-h5-color-b: #FEC600;
    --st-box-bg: #FFF;
    --st-box-color: #000;
    --score-tb-head-bg: #910500;
    --score-tb-bg: linear-gradient(#5B0001,#8B0000);
    --score-tb-th: #FF592E;
    --score-tb-table-bg: #FFF;
    --score-tb-table-color: #000;
    --score-tb-btn-yellow-bg: radial-gradient(ellipse 60% 50%,#EEE85B,#FFC400);
    --score-tb-btn-yellow-color: #000;
    --score-tb-btn-yellow-ts: 1px 2px #FF7F0029;
    --score-tb-btn-blue-bg: radial-gradient(#5CADFF,#006CFFD0);
    --score-tb-btn-blue-color: #FFF;
    --score-tb-btn-blue-ts: 1px 2px #00000014;

    /*leaderboard*/
    --leaderboard-bg: #330000;
    --leaderboard-top-bg: radial-gradient(#F2682F,#A00134);
    --lt-inner-box-bg: #A00134;
    --lt-inner-box-border: #FFF;
    --lt-inner-box-color-top: #FED804;
    --leaderboard-bottom-bg: linear-gradient(#650000,#330000);
    --lb-wrapper-bg: #FFF;
    --lb-navtabs-bg: #EDEDED;
    --lb-navtabs-color: #404040;
    --lb-navtabs-active-bg: #FFB100;
    --lb-navtabs-active-color: #FFF;
    --lb-navtabs-active-ts: 0px 2px #DD43001A;
    --lb-wrapper-table-bg: #EC1C24;
    --lb-wrapper-table-th-bg: #D60008;
    --lb-wrapper-table-th-color: #FFC400;
    --lb-wrapper-table-border: 1px solid #FFF;

    /*topup*/
    --topup-bg: #FF4E00;
    --topup-body-head-color: #000;
    --topup-box-bg: #E85138;
    --topup-box-border: 4px solid #C12106;
    --topup-box-head-bg: #D9381D;
    --topup-box-wrapper-bg: #FFF;
    --topup-box-wrapper-color: #000;
    --btn-topup-power-bg: #FD7F00;
    --btn-topup-power-color: #FFF;
    --btn-topup-power-border: 2px solid #D56B01;
    --btn-topup-power-ts: 1px 2px #D56B01;
    --btn-topup-heart-bg: #F51000;
    --btn-topup-heart-border: 2px solid #BF0C00;
    --btn-topup-heart-ts: 1px 2px #91001C4D;
    --modal-topup-color: #000;

    /*question main*/
    --question-main-bg: linear-gradient(#003D5A,#005286);
    --qm-head-bg: #003757;
    --qm-head-btn-bg: #FFF;
    --qm-head-btn-color: #FD6200;
    --qmw-top-bg: #002943;
    --qm-answer-bg: #000;
    --qm-answer-div-bg: #B1B1B1;
    --qm-answer-div-selected-bg: #575757;
    --qm-answer-div-correct-bg: #1CAD57;
    --qm-answer-div-wrong-bg: #FF2727;
    --qm-answer-div-hide-bg: #2C2C2C;
    --qm-answer-div-hide-color: #404040;

    /*home main*/
    --btn-hm-main-bg: radial-gradient(#EEE85B,#FFC400);
    --btn-hm-main-color: #000;
    --btn-hm-main-ts: 1px 2px #FF7F0029;
    --hm-inner-btn-bg: #1CAD57;
    --hm-inner-btn-color: #FFF;
    --hm-inner-btn-ts: 1px 2px #00000014;
    /*--hm-carousel-bg: #054777;*/
    --hm-carousel-bg: #006873;

    /*notif*/
    --notif-white-bg: #FFF;
    --notif-white-color: #000;
    --btn-notif-bg: radial-gradient(#747474,#313131);
    --btn-notif-color: #FFF;
    --btn-notif-ts: 1px 2px #42900029;
    --btn-notif-border: 3px solid #5A5A5A;
    --notif-poin-color: #000;
    --notif-congrats-h1-color: #EBAF02;
    --notif-congrats-h1-ts: 2px 2px 0 #FFF, 2px -2px 0 #FFF, -2px 2px 0 #FFF, -2px -2px 0 #FFF, 2px 0px 0 #FFF, 0px 2px 0 #FFF, -2px 0px 0 #FFF, 0px -2px 0 #FFF;
    --nc-inner-color: #000;

    /*text*/
    --text-head-bg: #011F2F;
    --text-head-color: #FFF;
    --text-color: #FFF;
    --text-table-head-bg: #0072B7;
    --text-table-border: 1px solid #FFF;
    --acc-head: #6AC0FF;
    --acc-item-bg: #002B4B;
    --acc-item-border: 1px solid #075886;

    /*setting*/
    --setting-item-bg: #002B4B;
    --setting-item-color: #FFF;
    --setting-item-border: 1px solid #075886;
    --setting-btn-purp-bg: #576AFF;
    --setting-btn-pink-bg: #FF5764;

    /*edit*/
    --edit-btn-bg: #1CAD57;
    --edit-btn-color: #FFF;
    --edit-btn-ts: 1px 2px #00000014;

    /*login*/
    /*--login-wrapper-bg: #054777;*/
    --login-wrapper-bg: #012734;
    --login-btn-bg: radial-gradient(#EEE85B,#FFC400);
    --login-btn-color: #000;
    --login-btn-ts: 1px 2px #FF7F0029;
    /*--signup-btn-bg: #054777;*/
    --signup-btn-bg: #1CAD57;
    --signup-btn-color: #FFF;
    --signup-btn-border: 1px solid #FFF;
    --signup-btn-ts: 1px 2px #FF7F0029;

    /*join*/
    --join-bg: linear-gradient(#085185,#043F5F);
    --join-inner-h6-color: #FFE200;
    --ji-wrapper-bg: #003450;
    --modal-join-bg: #0086D9;
    --mj-wrapper-bg: #25609A;
    --mj-inner-bg: radial-gradient(#3784D8,#112D5F);
    --mj-inner-h5-color: #FFF;

    /*history*/
    --history-poin-bg: radial-gradient(#1383C3 20%,#0A4262 80%);
    --history-table-top-bg: radial-gradient(#FFD93E 20%,#FF9300 80%);
    --history-table-head-bg: #002D45;
    --history-table-body-one: rgba(92,173,255,.3);
    --history-table-body-two: rgba(28,106,164,.66);
    --history-button-bottom-bg: radial-gradient(#5CADFF,#006CFFD0);
    --history-button-bottom-color: #FFF;
    --history-button-bottom-ts: 1px 2px #00000014;
    --history-main-btn-bg: radial-gradient(ellipse 60% 50%,#58F2A6,#00AD57);
    --history-main-btn-color: #FFF;
    --history-main-btn-shadow: 1px 2px #42900029;

    /*get ready*/
    --gr-btn-active-bg: radial-gradient(ellipse 60% 50%,#B9B9B9,#8B8B8B);
    --gr-btn-active-border: 3px solid #B1B1B1;
    --gr-time-ts: 3px 3px #00000029;
    --spread-bg: #FFF;

    /*popup text*/
    --modal-text-color: #000;
    --modal-text-btn-bg: #000;
    --modal-text-btn-color: #FFF;
}