.all-category{
    position: absolute;
    width: 100%;
}
.all-category-wrapper{
    position: fixed;
    width: inherit;
    max-width: 480px;
    height: 100%;
    overflow-y: auto;
    padding: 1.5em 1.5em 5.5em;
    -ms-overflow-style: none;
    scrollbar-width: none;
    max-height: 478px;
    background-image: var(--background);
    background-repeat: no-repeat;
    background-size: 100% 175%;
}
.all-category-wrapper::-webkit-scrollbar{
    display: none;
}
.all-category-inner{
    display: grid;
    grid-template-columns: repeat(2,50%);
    margin: -.5em;
    margin-top: 0;
}
.all-category-inner a{
    padding: .55em .5em;
}
.ac-item{
    position: relative;
    padding-bottom: 61.18%;
}
.ac-item img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}
.aci-title{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-weight: 500;
}

@media only screen and (min-width: 992px) and (max-height: 700px){
    .all-category-wrapper{
        max-width: 375px;
    }
}
@media only screen and (max-width: 375px) and (min-height: 800px){
    .all-category-wrapper{
        max-height: 518px;
    }
}
@media only screen and (max-height: 700px){
    .all-category-wrapper{
        padding-bottom: 12em;
    }
}
@media only screen and (max-height: 600px){
    .all-category-wrapper{
        padding-bottom: 19em;
    }
}
@media only screen and (max-height: 500px){
    .all-category-wrapper{
        padding-bottom: 26em;
    }
}