.waiting-outer{
    height: unset;
    max-height: unset;
}
.waiting-logo{
    padding: 0 1.5em;
    margin-bottom: .25em;
}
.waiting-logo img{
    width: 100%;
}
.waiting-prize{
    background-image: url('https://s.tykcdn.com/i/mq/images/prize-bg.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    padding-bottom: 100%;
    margin: 0 1.5em;
}
.waiting-prize img{
    position: absolute;
    width: 80%;
    height: 80%;
    object-fit: contain;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.waiting-box{
    position: relative;
    background-image: var(--waiting-box-bg);
    border: var(--waiting-box-border);
    border-radius: 5px;
    margin: 1em 1.25em 0;
    text-align: center;
    padding: .5em;
    margin-bottom: 3em;
}
.waiting-time{
    font-size: var(--fsizebig2);
    font-weight: 500;
    line-height: .7;
    padding-bottom: .5em;
}

@media only screen and (max-width: 992px) and (max-height: 812px){
    .waiting-outer{
        min-height: 100vh;
    }
}