.join{
    background-image: var(--join-bg);
    height: unset;
    max-height: unset;
}
.join-inner{
    padding: 1.25em 1em .25em;
}
.join-inner h5{
    font-size: var(--fsize5);
}
.join-inner h6{
    color: var(--join-inner-h6-color);
    font-size: var(--fsize3);
    font-weight: 100;
}
.ji-wrapper{
    background-color: var(--ji-wrapper-bg);
    padding: 1.15em 1em;
    border-radius: 10px;
    margin: 1.5em 0 1.75em;
}
.join-inner .btn{
    width: 100%;
    border-radius: 10px;
    font-size: var(--fsize4);
    padding: .35em .5em;
    text-shadow: var(--edit-btn-ts);
    -webkit-box-shadow: var(--shadow2);
    -moz-box-shadow: var(--shadow2);
    box-shadow: var(--shadow2);
}
.join-btn-join{
    background-image: var(--login-btn-bg);
    color: var(--login-btn-color) !important;
    font-weight: 600;
}
.join-or{
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #FFF;
    line-height: 0;
    margin: 1.5em 0;
    font-size: var(--fsize3);
} 
.join-or span{
    background: var(--ji-wrapper-bg); 
    padding: 0 1em; 
}
.jiw-text{
    font-size: var(--fsize2);
    line-height: 1.3;
}
.join-btn-signin{
    background-color: var(--signup-btn-bg);
    color: var(--signup-btn-color) !important;
    border: var(--signup-btn-border);
    font-weight: 500;
    margin: 1.5em auto;
    width: 92.5% !important;
}

.join-bottom .btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5em 1.15em;
    background-color: var(--setting-item-bg);
    color: var(--setting-item-color);
    border-top: var(--setting-item-border);
    font-size: var(--fsize3);
}
.join-bottom .btn:last-child{
    border-bottom: var(--setting-item-border);
}
.join-bottom .btn span{
    display: flex;
    align-items: center;
}
.join-bottom .btn span:nth-child(2) img{
    height: 1em;
}
.jb-img{
    background-color: var(--setting-btn-purp-bg);
    position: relative;
    width: 2em;
    height: 2em;
    border-radius: 5px;
    margin-right: 1.5em;
}
.jb-img img{
    position: absolute;
    width: 60%;
    height: auto;
    object-fit: contain;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.modal-join .modal-content{
    width: calc(100% - 2.75em);
    margin: 0 auto;
    background-color: var(--modal-join-bg);
    padding: .35em;
    border-radius: 15px;
}
.mj-wrapper{
    background-color: var(--mj-wrapper-bg);
    padding: 0 .45em;
    border-radius: 10px;
}
.mj-inner{
    background-image: var(--mj-inner-bg);
    padding: 3em 1em 4em;
    border-radius: 12px;
}
.mj-inner h5{
    color: var(--mj-inner-h5-color);
    text-align: center;
}
.mji-wrapper{
    display: grid;
    grid-template-columns: repeat(2,50%);
    margin: auto -.5em;
    margin-top: 1.5em;
}
.mji-wrapper a{
    padding: .6em .5em;
}
.join .copyright{
    position: relative;
    text-align: center;
    padding: 1.5em 0;
    bottom: unset;
}

.operator{
    padding: .6em .5em;
    cursor: pointer;
}

@media only screen and (max-width: 400px){
    .modal-join .modal-content{
        width: calc(100% - 0.5em);
    }
}
@media only screen and (max-width: 992px) and (max-height: 700px){
    .join{
        min-height: 100vh;
    }
}