.leaderboard{
    background-color: var(--leaderboard-bg);
    height: unset;
    max-height: unset;
}
.leaderboard-top{
    background-image: var(--leaderboard-top-bg);
    padding: 6em 2em 1.5em;
    margin-top: -6.5em;
}
.leaderboard-top h5{
    font-weight: 300;
    text-align: center;
    margin-bottom: .65em;
}
.leaderboard-top h5 b{
    font-weight: 500;
}
.lt-inner{
    display: flex;
    align-self: center;
    justify-content: center;
}
.lt-inner span{
    background-color: var(--lt-inner-box-bg);
    border-radius: 10px;
    border: 1px solid var(--lt-inner-box-border);
    width: 50%;
    text-align: center;
    padding: 1.25em .5em 1.75em;
    margin-right: 1.25em;
}
.lt-inner span:last-child{
    margin-right: 0;
}
.lt-inner span div:nth-child(1){
    color: var(--lt-inner-box-color-top);
    font-size: var(--fsize1);
    font-weight: 500;
    line-height: 1;
    margin-bottom: .75em;
}
.lt-inner span div:nth-child(2){
    font-size: var(--fsizebig2);
    font-weight: 500;
    line-height: .8;
}
.leaderboard-bottom{
    background-image: var(--leaderboard-bottom-bg);
    padding: 1.75em 1em 3em;
}
.lb-wrapper{
    background-color: var(--lb-wrapper-bg);
    border-radius: 10px;
    padding: 1em;
}
.lb-wrapper .nav-tabs{
    border-bottom: none;
    display: flex;
    justify-content: center;
    padding: 0 1em;
    margin-bottom: 1.25em;
}
.lb-wrapper .nav-item{
    width: 50%;
    text-align: center;
}
.lb-wrapper .nav-link{
    background-color: var(--lb-navtabs-bg);
    color: var(--lb-navtabs-color);
    font-family: 'Open Sans', '-apple-system', 'BlinkMacSystemFont', sans-serif;
    font-weight: 700;
    border: none;
    border-radius: 20px;
    margin-right: .75em;
    padding: .75em;
}
.lb-wrapper .nav-item:last-child .nav-link{
    margin-right: 0;
}
.lb-wrapper .nav-item.show .nav-link, .lb-wrapper .nav-link.active{
    border: none;
    background-color: var(--lb-navtabs-active-bg);
    color: var(--lb-navtabs-active-color);
    text-shadow: var(--lb-navtabs-active-ts);
}
.lbi-head{
    background-color: var(--lb-wrapper-table-bg);
    border-radius: 8px 8px 0 0;
    text-align: center;
    font-size: var(--fsize2);
    font-weight: 500;
    padding: .75em 0;
}
.lb-inner table{
    background-color: var(--lb-wrapper-table-bg);
    width: 100%;
    border-radius: 0 0 8px 8px;
    font-size: var(--fsize2);
    margin-bottom: 1.15em;
}
.lb-inner table th{
    background-color: var(--lb-wrapper-table-th-bg);
    color: var(--lb-wrapper-table-th-color);
    font-size: var(--fsize1);
}
.lb-inner table tr{
    border-bottom: var(--lb-wrapper-table-border);
}
.lb-inner table tr:first-child, .lb-inner table tr:last-child{
    border-bottom: none;
}
.lb-inner table tr:last-child td{
    padding-bottom: 1em;
}
.lb-inner table td{
    padding: .5em .75em;
}
.lb-inner table th{
    padding: .75em .5em;
}
.lb-inner table th:last-child, .lb-inner table td:last-child{
    padding-right: 1em;
}
.lb-inner table th:nth-child(1), .lb-inner table td:nth-child(1){
    text-align: center;
}
.lb-inner table th:nth-child(2), .lb-inner table td:nth-child(2){
    text-align: left;
}
.lb-inner table th:nth-child(3), .lb-inner table td:nth-child(3){
    text-align: right;
}

.table-winner-m th:nth-child(1), .table-winner-m td:nth-child(1){
    text-align: left !important;
}
.table-winner-m th:nth-child(2), .table-winner-m td:nth-child(2){
    text-align: center !important;
}
.table-winner-m th:nth-child(3), .table-winner-m td:nth-child(3){
    text-align: center !important;
}

.table-winner-lq th:nth-child(1), .table-winner-lq td:nth-child(1){
    text-align: left !important;
}
.table-winner-lq th:nth-child(2), .table-winner-lq td:nth-child(2){
    text-align: center !important;
}

.table-winner-m th:nth-child(1), .table-winner-m td:nth-child(1), .table-winner-lq th:nth-child(1), .table-winner-lq td:nth-child(1){
    padding-left: 1em;
}

@media only screen and (max-width: 992px) and (max-height: 700px){
    .leaderboard{
        min-height: 100vh;
    }
}