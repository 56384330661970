.score{
    background-color: var(--score-bg);
    background-image: unset;
    height: unset;
    max-height: unset;
    overflow: hidden;
}
.score-top{
    position: relative;
    margin-top: -60%;
    margin-bottom: -27.5%;
    overflow: hidden;
}
.st-burst{
    position: relative;
    padding-bottom: 100%;
}
.st-burst-wrapper{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 67.5%;
    transform: translate(-50%,0);
    border-radius: 50%;
}
.st-burst-inner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 170%;
    height: 170%;
    background-image: url('https://s.tykcdn.com/i/mq/images/brust.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    border-radius: 50%;
    opacity: .6;
}
.stbi-bg{
    background-image: var(--stbi-gd);
    padding-bottom: 100%;
    opacity: .9;
}
.score-total{
    position: relative;
}
.score-total-top{
    position: relative;
    background-color: var(--score-total-top-bg);
    border-radius: 50%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 5em 0;
    margin-bottom: -5em;
}
.score-total-bottom{
    position: relative;
    background-image: var(--score-total-bg);
    padding: 0 1em 2.25em;
}
.st-circle{
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    align-items: center;
    justify-content: center;
}
.st-circle span{
    width: 50%;
    max-width: 8em;
    margin: 0 2.5em;
}
.st-circle-item{
    background-image: var(--st-circle-bg);
    border: var(--st-circle-border);
    position: relative;
    padding-bottom: 92.5%;
    border-radius: 50%;
}
.st-circle-inner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    text-shadow: var(--st-circle-inner-ts);
}
.st-circle-inner div:nth-child(1){
    font-size: var(--fsizebig2);
    font-weight: 500;
    line-height: .7;
    margin-top: .25em;
}
.st-circle-inner div:nth-child(2){
    font-size: var(--fsize3);
}
.st-circle-x{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    display: flex;
    align-items: center;
    font-weight: 300;
}
.st-circle-x div:nth-child(1){
    font-size: var(--fsize5);
}
.st-circle-x div:nth-child(2){
    font-size: var(--fsize6);
    margin-left: .05em;
}
.score-total h5{
    text-align: center;
    font-weight: 300;
}
.score-total h5 b{
    font-weight: 500;
    color: var(--st-h5-color-b);
}
.st-box{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.25em;
}
.st-box span{
    background-color: var(--st-box-bg);
    border-radius: 8px;
    color: var(--st-box-color);
    width: 50%;
    max-width: 9em;
    padding: .75em .5em;
    margin: 0 .5em;
    -webkit-box-shadow: var(--shadow2);
    -moz-box-shadow: var(--shadow2);
    box-shadow: var(--shadow2);
}
.st-box img{
    width: 2.25em;
    margin: 0 auto 1.5em;
    display: block;
}
.st-box h6{
    text-align: center;
    margin-top: .65em;
    line-height: 0;
}
.score-tb-head{
    position: relative;
    background-color: var(--score-tb-head-bg);
    font-size: var(--fsize3);
    text-align: center;
    padding: .65em;
    font-weight: 300;
}
.score-tb-head b{
    font-weight: 500;
}
.score-tb{
    position: relative;
    background-image: var(--score-tb-bg);
    padding: 1.75em 1em 3em;
}
.score-tb table{
    background-color: var(--score-tb-table-bg);
    color: var(--score-tb-table-color);
    width: 100%;
    border-radius: 8px;
    font-size: var(--fsize2);
}
.score-tb table th{
    color: var(--score-tb-th);
    font-size: var(--fsize1);
}
.score-tb table tr:last-child td{
    padding-bottom: 1em;
}
.score-tb table td{
    padding: .5em;
}
.score-tb table th{
    padding: .75em .5em;
}
.score-tb table th:last-child, .score-tb table td:last-child{
    padding-right: 1em;
}
.score-tb table th:nth-child(1), .score-tb table td:nth-child(1){
    text-align: center;
}
.score-tb table th:nth-child(2), .score-tb table td:nth-child(2){
    text-align: left;
}
.score-tb table th:nth-child(3), .score-tb table td:nth-child(3){
    text-align: right;
}
.btn-score-yellow{
    background-image: var(--score-tb-btn-yellow-bg);
    color: var(--score-tb-btn-yellow-color) !important;
    text-shadow: var(--score-tb-btn-yellow-ts);
    border-radius: 10px;
    font-size: var(--fsize4);
    font-weight: 600;
    padding: .45em .5em;
    margin: 1.5em auto .7em;
    -webkit-box-shadow: var(--shadow2);
    -moz-box-shadow: var(--shadow2);
    box-shadow: var(--shadow2);
}
.score-tb-button{
    display: flex;
    align-items: center;
    justify-content: center;
}
.score-tb-button .btn{
    background-image: var(--score-tb-btn-blue-bg);
    color: var(--score-tb-btn-blue-color) !important;
    text-shadow: var(--score-tb-btn-blue-ts);
    border-radius: 10px;
    width: 50%;
    font-size: var(--fsize3);
    font-weight: 500;
    line-height: 1.2;
    padding: 1em 1em;
    margin-right: .65em;
    -webkit-box-shadow: var(--shadow2);
    -moz-box-shadow: var(--shadow2);
    box-shadow: var(--shadow2);
}
.score-tb-button .btn:last-child{
    margin-right: 0;
}

@media only screen and (max-width: 992px) and (max-height: 812px){
    .score{
        min-height: 100vh;
    }
}