.home-main{
    background-image: url('https://s.tykcdn.com/i/mq/images/bg-mq-top.svg'),url('https://s.tykcdn.com/i/mq/images/bg-mq.jpg');
    background-size: contain,cover;

    /*background-image: url('http://tykcdn.com/img500/match_quiz/web/background_Matchquiz_WEB_full-screen_ramadhan.jpg');
    background-size: cover;*/

    background-repeat: no-repeat,no-repeat;

    background-position: top,center;
    height: unset;
    max-height: unset;
}
.hm-logo{
    padding: 5em 0 1.75em;
}
.hm-logo img{
    max-width: 10em;
    margin: 0 auto;
    display: block;
}
.hm-banner{
    position: relative;
    padding-bottom: 60%;
    width: 90%;
    margin: 0 auto;
    display: block;
    background-image: linear-gradient(to right,#FFF,#D6D6D6,#FFF);
    background-size: 200% 200%;

    -webkit-animation: bgPhd 2s ease infinite;
    -moz-animation: bgPhd 2s ease infinite;
    -ms-animation: bgPhd 2s ease infinite;
    -o-animation: bgPhd 2s ease infinite;
    animation: bgPhd 2s ease infinite;
}
@-webkit-keyframes bgPhd{
    0%{ background-position: 200% 0% }
    100%{ background-position: 0% 200% }
}
@-moz-keyframes bgPhd{
    0%{ background-position: 200% 0% }
    100%{ background-position: 0% 200% }
}
@-o-keyframes bgPhd{
    0%{ background-position: 200% 0% }
    100%{ background-position: 0% 200% }
}
@keyframes bgPhd{
    0%{ background-position: 200% 0% }
    100%{ background-position: 0% 200% }
}
.hm-banner img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.hm-btn-wrapper{
    padding: 2em 1em;
}
.hm-btn-wrapper .btn{
    border-radius: 10px;
}
.btn-hm-main{
    background-image: var(--btn-hm-main-bg);
    color: var(--btn-hm-main-color);
    text-shadow: var(--btn-hm-main-ts);
    width: 100%;
    font-size: var(--fsize4);
    font-weight: 600;
    padding: .35em 1em;
}
.hm-inner-btn-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: .75em;
}
.hm-inner-btn-wrapper .btn{
    background-color: var(--hm-inner-btn-bg);
    color: var(--hm-inner-btn-color);
    text-shadow: var(--hm-inner-btn-ts);
    width: 100%;
    font-size: var(--fsize1);
    font-weight: 500;
    padding: .6em 1em;
    letter-spacing: .5px;
}
.btn-setting{
    width: 4em !important;
    margin-left: .75em;
}
.btn-setting:active img{
    transition: 200ms;
}
.btn-setting:active img, .btn-setting:focus img{
    transform: rotate(60deg);
}
.hm-carousel{
    background-color: var(--hm-carousel-bg);
    padding: 1em 1.5em 2em;
}
.hm-carousel h6{
    font-weight: 300;
}
.hm-carousel .carousel-cell:first-child{
    left: 1.5em !important;
    margin-right: 2.15em !important;
}
.hm-carousel .carousel-cell{
    width: 66%;
    margin-right: .65em;
}
.hm-carousel .last-slide{
    margin-left: .85em !important;
}
.hmc-item{
    position: relative;
    padding-bottom: 66.38%;
}
.hmc-item img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 992px) and (max-height: 812px){
    .home-main{
        min-height: 100vh;
    }
}
@media only screen and (max-width: 375px) and (min-height: 800px){
    .hm-carousel{
        position: absolute;
        width: 100%;
        bottom: 0;
    }
}