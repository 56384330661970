.main-head{
  position: sticky;
  top: 0;
  background-color: var(--text-head-bg);
  color: var(--text-head-color);
  text-align: center;
  padding: 1.15em .5em;
}
.main-head .btn{
  position: absolute;
  top: 50%;
  left: .75em;
  transform: translate(0,-50%);
}
.main-head .btn:active{
  transform: translate(0,-50%) scale(.98);
}
.main-head .btn:focus:not(:active)::after{
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  -o-animation: none;
  animation: none;
}
.main-head .btn img{
  height: 1.4em;
  width: auto;
}
.main-head h6{
  margin-bottom: 0;
  font-weight: 600;
  font-size: var(--fsize3);
  font-weight: 500;
}