.question-main{
    background-image: var(--question-main-bg);
    height: unset;
    max-height: unset;
}
.qm-head{
    background-color: var(--qm-head-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3.5em 2.5em .65em;
    margin-top: -4em;
}
.qm-head .btn{
    background-color: var(--qm-head-btn-bg);
    color: var(--qm-head-btn-color);
    font-size: var(--fsmall2);
    font-weight: 500;
    border-radius: 5px;
    padding: .25em 1em;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 1.25em;
    min-height: 3em;
}
.qm-head .btn:last-child{
    margin-right: 0;
}
.qm-head .btn.sup-selected{
    background-color: var(--qm-head-btn-color);
    color: var(--qm-head-btn-bg);
    pointer-events: none;
}
.svg-time{
    height: 2.15em;
    width: auto;
}
.svg-fifty{
    height: 2em;
    width: auto;
}
.qm-head svg .fill-o, .qm-head .sup-selected svg .fill-w{
    fill: var(--qm-head-btn-color);
}
.qm-head svg .stroke-o{
    stroke: var(--qm-head-btn-color);
}
.qm-head svg .fill-w, .qm-head .sup-selected svg .fill-o{
    fill: var(--qm-head-btn-bg);
}
.qm-head svg .stroke-o, .qm-head .sup-selected svg .stroke-w{
    stroke: var(--qm-head-btn-color);
}
.qm-head svg .stroke-w, .qm-head .sup-selected svg .stroke-o{
    stroke: var(--qm-head-btn-bg);
}
.question-main-wrapper{
    padding: 1em;
    margin-top: 1em;
}
.qmw-top{
    position: relative;
    background-color: var(--qmw-top-bg);
    padding: .5em;
}
.qmw-top-img{
    position: relative;
    padding-bottom: 75%;
}
.qmw-top-img img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
}
.qmw-top-text{
    font-size: var(--fsize3);
    text-align: center;
    line-height: 1.2;
    padding: .75em .75em .5em;
}
.timer{
    z-index: 1;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,-50%);
    height: 42px;
    width: 42px;
    text-align: center;
    background-color: var(--timer-bg);
    border-radius: 50%;
}
.timer h4{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: var(--timer-color);
    display: inline-block;
}
.timer svg{
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 58px;
    max-height: 58px;
    transform: translate(-50%, -50%) rotateY(-180deg) rotateZ(-90deg);
}
.timer .circle_animation{
    stroke-dasharray: 150;
    /*stroke-dashoffset: 150;*/
    stroke-width: 6;
    stroke: var(--timer-circle-color);
    fill: none;
    transition: all 1s linear;
}

.qm-answer{
    position: relative;
    background-color: var(--qm-answer-bg);
    border-radius: 40px 40px 0 0;
    padding: 1.5em 1em;
}
.qma-item{
    background-color: var(--qm-answer-div-bg);
    margin-bottom: .45em;
    border-radius: 10px;
    text-align: center;
    font-size: var(--fsize2);
    padding: .65em 1em;
    line-height: 1.1;
}
.qma-item:last-child{
    margin-bottom: 0;
}
.qma-item.selected{
    background-color: var(--qm-answer-div-selected-bg);
}
.qma-item.correct{
    background-color: var(--qm-answer-div-correct-bg);
}
.qma-item.wrong{
    background-color: var(--qm-answer-div-wrong-bg);
}
.qma-item.hide{
    background-color: var(--qm-answer-div-hide-bg);
    color: var(--qm-answer-div-hide-color);
}

/* loading */
.loading-q{
    z-index: 2;
	background-color: var(--loading-q-bg);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 90%;
    padding: 15px 20px;
    border-radius: 20px;
    -webkit-box-shadow: var(--shadow);
	-moz-box-shadow: var(--shadow);
	box-shadow: var(--shadow);
}
.lq-text, .lq-bottom{
	margin-top: 10px;
	text-align: center;
	font-size: var(--fsize6);
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 15px;
}
.lq-bottom{
	color: #ECECEC;
	font-size: var(--fsize2);
	text-transform: none;
    font-weight: 300;
    line-height: 1.3;
}
.loading-q .meter > span{
	width: 100%;
	-webkit-border-top-right-radius: 20px;
	-webkit-border-bottom-right-radius: 20px;
	-moz-border-radius-topright: 20px;
	-moz-border-radius-bottomright: 20px;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
}

.meter{ 
    bottom: 20px;
    left: 0; 
    right: 0;
    margin: 0 auto;
    height: 55px;
    width: 80%;
    border-radius: 20px;
    background: #014C74;
    padding: 6px;
    -webkit-box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
    -moz-box-shadow   : inset 0 -1px 1px rgba(255,255,255,0.3);
    box-shadow        : inset 0 -1px 1px rgba(255,255,255,0.3);
}
.meter > span {
    display: block;
    width: 70%;
    height: 100%;
    -webkit-border-top-right-radius: 8px;
    -webkit-border-bottom-right-radius: 8px;
    -moz-border-radius-topright: 8px;
    -moz-border-radius-bottomright: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-bottom-left-radius: 20px;
    -moz-border-radius-topleft: 20px;
    -moz-border-radius-bottomleft: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #FFD93E;
    -webkit-box-shadow: 
      inset 0 2px 9px  rgba(255,255,255,0.3),
      inset 0 -2px 6px rgba(0,0,0,0.4);
    -moz-box-shadow: 
      inset 0 2px 9px  rgba(255,255,255,0.3),
      inset 0 -2px 6px rgba(0,0,0,0.4);
    box-shadow: 
      inset 0 2px 9px  rgba(255,255,255,0.3),
      inset 0 -2px 6px rgba(0,0,0,0.4);
    position: relative;
    overflow: hidden;
}
.meter > span:after, .animate > span > span {
    content: "";
    position: absolute;
    top: 0; left: 0; bottom: 0; right: 0;
    background-image: linear-gradient(
    -45deg, 
    rgba(255, 255, 255, .2) 25%, 
    transparent 25%, 
    transparent 50%, 
    rgba(255, 255, 255, .2) 50%, 
    rgba(255, 255, 255, .2) 75%, 
    transparent 75%, 
    transparent
    );    
    z-index: 1;
    -webkit-background-size: 50px 50px;
    -moz-background-size: 50px 50px;
    background-size: 50px 50px;

    -webkit-animation: Move 2s linear infinite;
    -moz-animation: Move 2s linear infinite;
    -ms-animation: Move 2s linear infinite;
    -o-animation: Move 2s linear infinite;
    animation: Move 2s linear infinite;

    -webkit-border-top-right-radius: 8px;
    -webkit-border-bottom-right-radius: 8px;
    -moz-border-radius-topright: 8px;
    -moz-border-radius-bottomright: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-bottom-left-radius: 20px;
    -moz-border-radius-topleft: 20px;
    -moz-border-radius-bottomleft: 20px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
}

.animate > span:after{
    display: none;
}

@-webkit-keyframes Move{
    0% {
       background-position: 0 0;
    }
    100% {
       background-position: 50px 50px;
    }
}
@-moz-keyframes Move{
    0% {
       background-position: 0 0;
    }
    100% {
       background-position: 50px 50px;
    }
}
@-o-keyframes Move{
    0% {
       background-position: 0 0;
    }
    100% {
       background-position: 50px 50px;
    }
}
@keyframes Move{
    0% {
       background-position: 0 0;
    }
    100% {
       background-position: 50px 50px;
    }
}
/* end loading */

@media only screen and (max-width: 992px) and (max-height: 812px){
    .question-main{
        min-height: 100vh;
    }
}
@media only screen and (max-width: 375px) and (min-height: 800px){
    .qm-answer{
        position: absolute;
        bottom: 0;
    }
}