header{
  position: sticky;
  top: 0;
  background-image: var(--header-bg);
  padding: .5em 0 2em;
  border-radius: 50%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  -webkit-box-shadow: var(--shadow);
  -moz-box-shadow: var(--shadow);
  box-shadow: var(--shadow);
  margin-bottom: 2em;
  z-index: 2;
}
header h6{
  text-align: center;
  text-shadow: var(--h-ts);
  letter-spacing: 1px;
}
.header-inner{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%,25%);
  width: max-content;
  background-image: var(--header-inner-bg);
  border-radius: 20px;
  font-size: var(--fsmall3);
  font-weight: 500;
  padding: .65em 1.25em;
  display: flex;
  justify-content: center;
  min-width: 27em;
}
.btn-main-back{
  position: absolute;
  top: 35%;
  transform: translate(0,-50%);
  left: .75em;
  width: 1.5em;
}
.btn-main-back:active{
  transform: translate(0,-50%) scale(.98);
}
.header-inner span{
  border-radius: 20px;
  padding-right: 10px;
  margin-right: .75em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 23px;
}
.header-inner span:last-child{
  margin-right: 0;
}
.header-inner img{
  max-width: 23px;
  margin: -2px .5em -2px -2px;
}
.hi-green{
  background-image: var(--hi-green-bg);
  border: var(--hi-green-border);
  text-shadow: var(--hi-green-ts);
}
.hi-yellow{
  background-image: var(--hi-yellow-bg);
  border: var(--hi-yellow-border);
  text-shadow: var(--hi-yellow-ts);
}
.hi-topup{
  padding-right: 0 !important;
}
.hi-topup img{
  margin: 0;
}

@media only screen and (max-width: 375px){
  .header-inner{
    font-size: var(--fsmall4);
    min-width: 29em;
  }
}
@media only screen and (max-width: 350px){
  .header-inner{
    min-width: 30em;
  }
}