.slider-category{
    background-color: var(--slider-category-bg);
    padding: 0 1.5em 1.75em;
    border-bottom: var(--hr);
}
.slider-category .carousel-cell{
    width: 66%;
}
.sc-item{
    position: relative;
    padding-bottom: 58.65%;
    border-radius: 5px;
}
.sc-item img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    padding: 5px;
}
.sc-item-live{
    position: relative;
    background-color: var(--sc-item-live);
}
.sc-item-live img{
    -webkit-animation: live 1.5s linear infinite;
    -moz-animation: live 1.5s linear infinite;
    -ms-animation: live 1.5s linear infinite;
    -o-animation: live 1.5s linear infinite;
    animation: live 1.5s linear infinite;
}
@-webkit-keyframes live{
    0% { transform: scale(1); }
    50% { transform: scale(.98); }
    100% { transform: scale(1); }
}
@-moz-keyframes live{
    0% { transform: scale(1); }
    50% { transform: scale(.98); }
    100% { transform: scale(1); }
}
@-o-keyframes live{
    0% { transform: scale(1); }
    50% { transform: scale(.98); }
    100% { transform: scale(1); }
}
@keyframes live{
    0% { transform: scale(1); }
    50% { transform: scale(.98); }
    100% { transform: scale(1); }
}
.red-live{
    position: absolute;
    top: .65em;
    left: .65em;
    background-color: var(--sc-item-live);
    border-radius: 2px;
    padding: .15em .5em;
    font-size: var(--fsize2);
    display: flex;
    align-items: center;
}
.red-live span{
    width: .65em;
    height: .65em;
    background-color: #FFF;
    border-radius: 50%;
    display: inline-block;
    margin-right: .25em;
}