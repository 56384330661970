.get-ready-top h3{
    font-weight: 600;
    text-align: center;
    margin: 1.5em 0 1em;
}
.grt-wrapper{
    position: relative;
    max-width: 14em;
    margin: 0 auto;
}
.grt-gold{
    position: relative;
    background-image: url('https://s.tykcdn.com/i/mq/web/Gold.png');
    background-repeat: no-repeat;
    background-size: 100%;
    padding-bottom: 105%;
}
.grt-time{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: var(--fsizebig4);
    font-weight: 600;
    text-shadow: var(--gr-time-ts);
}
.grt-spread{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: var(--spread-bg);
    width: 10em;
    height: 10em;
    border-radius: 50%;

    transform-origin: top left;
    -webkit-animation: spreadBg 1.5s ease-out infinite;
    -moz-animation: spreadBg 1.5s ease-out infinite;
    -ms-animation: spreadBg 1.5s ease-out infinite;
    -o-animation: spreadBg 1.5s ease-out infinite;
    animation: spreadBg 1.5s ease-out infinite;
}
@-webkit-keyframes spreadBg{
    0%{ transform: scale(0) translate(-50%,-50%); opacity: 1 }
    100%{ transform: scale(3) translate(-50%,-50%); opacity: 0 }
}
@-moz-keyframes spreadBg{
    0%{ transform: scale(0) translate(-50%,-50%); opacity: 1 }
    100%{ transform: scale(3) translate(-50%,-50%); opacity: 0 }
}
@-o-keyframes spreadBg{
    0%{ transform: scale(0) translate(-50%,-50%); opacity: 1 }
    100%{ transform: scale(3) translate(-50%,-50%); opacity: 0 }
}
@keyframes spreadBg{
    0%{ transform: scale(0) translate(-50%,-50%); opacity: 1 }
    100%{ transform: scale(3) translate(-50%,-50%); opacity: 0 }
}

.get-ready-bottom{
    margin-top: 4em;
    text-align: center;
    padding-bottom: 3em;
}
.get-ready-bottom h4{
    margin-bottom: .25em;
}
.grb-active{
    display: flex;
    align-items: center;
    justify-content: center;
}
.grb-active img{
    width: 3.75em;
    margin-right: 1.25em;
}
.grb-active h1{
    font-size: var(--fsizebig2-2);
    margin-bottom: 0;
}
.gr-btn{
    background-image: var(--btn-check-in-bg);
    color: var(--btn-check-in-color) !important;
    border: var(--btn-check-in-border);
    border-radius: 20px;
    width: 100%;
    max-width: 11em;
    margin: 1.5em auto 0;
    font-size: var(--fsize4);
    padding: .35em 0;
    text-shadow: var(--btn-check-in-shadow);
    pointer-events: unset;
}
.gr-btn.active{
    background-image: var(--gr-btn-active-bg);
    border: var(--gr-btn-active-border);
    pointer-events: none;
}

.sprite-container{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.sprite-container .sc-wrapper:nth-child(1){
    left: 10%;
}
.sprite-container .sc-wrapper:nth-child(2){
    left: 30%;
    -webkit-animation-delay: .5s;
    -moz-animation-delay: .5s;
    -ms-animation-delay: .5s;
    -o-animation-delay: .5s;
    animation-delay: .5s;
}
.sprite-container .sc-wrapper:nth-child(3){
    left: 70%;
    -webkit-animation-delay: 1s;
    -moz-animation-delay: 1s;
    -ms-animation-delay: 1s;
    -o-animation-delay: 1s;
    animation-delay: 1s;
}
.sprite-container .sc-wrapper:nth-child(4){
    left: 75%;
    -webkit-animation-delay: 1.25s;
    -moz-animation-delay: 1.25s;
    -ms-animation-delay: 1.25s;
    -o-animation-delay: 1.25s;
    animation-delay: 1.25s;
}
.sc-wrapper{
    max-width: 3em;
    max-height: 3em;
    position: absolute;
    width: 100%;
    height: 100%;

    -webkit-animation: moveCoin 1s ease-in infinite;
    -moz-animation: moveCoin 1s ease-in infinite;
    -ms-animation: moveCoin 1s ease-in infinite;
    -o-animation: moveCoin 1s ease-in infinite;
    animation: moveCoin 1s ease-in infinite;
}
@-webkit-keyframes moveCoin{
    0%{ transform: translateY(-10vh) }
    100%{ transform: translateY(100vh) }
}
@-moz-keyframes moveCoin{
    0%{ transform: translateY(-10vh) }
    100%{ transform: translateY(100vh) }
}
@-o-keyframes moveCoin{
    0%{ transform: translateY(-10vh) }
    100%{ transform: translateY(100vh) }
}
@keyframes moveCoin{
    0%{ transform: translateY(-10vh) }
    100%{ transform: translateY(100vh) }
}
.sprite-coin{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 7em;
    max-height: 7em;
    height: 250%;
    width: 250%;
    background-image: url('https://s.tykcdn.com/i/mq/web/koin_sprite.png');
    background-size: cover;

    -webkit-animation: spriteCoin 5s steps(23) infinite;
    -moz-animation: spriteCoin 5s steps(23) infinite;
    -ms-animation: spriteCoin 5s steps(23) infinite;
    -o-animation: spriteCoin 5s steps(23) infinite;
    animation: spriteCoin 5s steps(23) infinite;
}
@-webkit-keyframes spriteCoin{
    0%{  background-position: 0 }
    100%{  background-position: -5750% }
}
@-moz-keyframes spriteCoin{
    0%{  background-position: 0 }
    100%{  background-position: -5750% }
}
@-o-keyframes spriteCoin{
    0%{  background-position: 0 }
    100%{  background-position: -5750% }
}
@keyframes spriteCoin{
    0%{  background-position: 0 }
    100%{  background-position: -5750% }
}

@media only screen and (max-width: 992px) and (max-height: 812px){
    .get-ready{
        height: unset;
        min-height: 100vh;
    }
}