.edit-form{
    padding: 2em 1.5em;
}
.edit-form label{
    font-size: var(--fsize2);
}
.edit-form input{
    border-radius: 10px;
    font-size: var(--fsize3);
    padding: 0 1.25em;
    height: 2.5em;
    -webkit-box-shadow: var(--shadow2);
    -moz-box-shadow: var(--shadow2);
    box-shadow: var(--shadow2);
}
.edit-form .btn{
    background-color: var(--edit-btn-bg);
    color: var(--edit-btn-color) !important;
    width: 100%;
    border-radius: 10px;
    font-size: var(--fsize4);
    font-weight: 500;
    padding: .35em .5em;
    margin-top: 6em;
    text-shadow: var(--edit-btn-ts);
    -webkit-box-shadow: var(--shadow2);
    -moz-box-shadow: var(--shadow2);
    box-shadow: var(--shadow2);
}

@media only screen and (max-height: 567px){
    .edit-form .btn{
        margin-top: 3em;
    }
}