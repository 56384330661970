.setting-btn .btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .5em 1.15em;
    background-color: var(--setting-item-bg);
    color: var(--setting-item-color);
    border-top: var(--setting-item-border);
    font-size: var(--fsize3);
}
.setting-btn .btn:last-child{
    border-bottom: var(--setting-item-border);
}
.setting-btn .btn span{
    display: flex;
    align-items: center;
}
.setting-btn .btn span:nth-child(2) img{
    height: 1em;
}
.sb-img{
    position: relative;
    width: 2em;
    height: 2em;
    border-radius: 5px;
    margin-right: 1.5em;
}
.sb-img img{
    position: absolute;
    width: 60%;
    height: auto;
    object-fit: contain;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.sb-top{
    margin-top: 2.25em;
}
.sb-top .sb-img{
    background-color: var(--setting-btn-purp-bg);
}
.sb-bottom{
    margin-top: 3.25em;
}
.sb-bottom .sb-img{
    background-color: var(--setting-btn-pink-bg);
}